<template>
  <ul class="clebex-item-section default-meeting-title">
    <li
      class="clebex-item-section-item full-right-underline"
      v-if="preferenceUpdateData.is_automatic"
    >
      <div class="clebex-item-content-wrapper">
        <div class="clebex-section-input">
          <label class="clebex-section-input-label">{{
            preferenceUpdateData.values[0].value
          }}</label>
        </div>
      </div>
    </li>
    <li
      class="clebex-item-section-item full-right-underline"
      v-if="!preferenceUpdateData.is_automatic"
    >
      <div class="clebex-item-content-wrapper">
        <div class="clebex-section-input">
          <VeeForm
            v-slot="{ errors }"
            class="form tiny-input edit-form"
            novalidate
            ><Field
              name="title"
              as="input"
              rules="required"
              type="text"
              id="meetingTItle"
              v-model="newValue"
              @keyup="newValue && setData()"
            />
            <span class="error-message" v-if="errors.title">
              {{ errors.name }}
            </span>
          </VeeForm>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "DefaultMeetingTitle",
  computed: {
    ...mapState("preferences", ["preferences", "preferenceUpdateData"])
  },
  data() {
    return {
      newValue: null
    };
  },
  mounted() {
    this.newValue = this.preferenceUpdateData.values[0].value;
  },
  props: {
    preferenceId: {
      type: Number,
      required: true
    }
  },
  methods: {
    ...mapActions("preferences", ["setPreferenceUpdateData", "setPreferences"]),
    setData() {
      if (this.preferenceUpdateData.is_automatic) {
        return;
      }
      const newPreferenceUpdateData = {
        is_automatic: this.preferenceUpdateData.is_automatic,
        values: [
          {
            value: this.newValue
          }
        ]
      };
      if (this.preferenceUpdateData.values[0].id) {
        newPreferenceUpdateData.values[0].id = this.preferenceUpdateData.values[0].id;
      }
      this.setPreferenceUpdateData(newPreferenceUpdateData);
    }
  }
};
</script>
